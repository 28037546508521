import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowUp,
  faCaretDown,
  faCheck,
  faChevronDown,
  faChevronUp,
  faFilter,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListSittingsResponse_SittingData,
  SittingDeliveryState,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Assessment, AssessmentStatus } from '@sparx/api/apis/sparx/assessment/v1/assessment';
import { useAssessments } from '@sparx/assessments/src/api/hooks';
import * as Select from '@radix-ui/react-select';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { useSubjects } from 'api/subjects';
import classNames from 'classnames';
import { Button } from 'components/button/Button';
import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sortSittings } from 'utils/sittings';

import styles from './Filters.module.css';

const useAllAssessments = () =>
  useAssessments(
    {
      subjectName: 'subjects/-',
      includeAssessmentGroupAssessments: false,
    },
    { suspense: true },
  );

interface SittingListFilters {
  subject: string;
  assessment: string;
  yeargroup: string;
}

const useFilteredSittings = (
  filters: SittingListFilters,
  sittings: ListSittingsResponse_SittingData[],
  sorting: { sort: string; flip: boolean },
) => {
  const { data: { assessments } = {} } = useAllAssessments();
  return useMemo(() => {
    const sorted = sortSittings(
      sittings.filter(sitting => {
        const assessment = assessments?.find(a => a.name === sitting.sitting?.assessmentName);
        return (
          sitting.sitting?.state?.state !== SittingDeliveryState.CANCELLED && // Exclude cancelled
          (!filters.subject || assessment?.subjectKey === filters.subject) &&
          (!filters.assessment || assessment?.name === filters.assessment) &&
          (filters.yeargroup === '' ||
            sitting?.sitting?.ukYeargroup === parseInt(filters.yeargroup))
        );
      }),
      sorting.sort,
    );
    if (sorting.flip) {
      return sorted.reverse();
    }
    return sorted;
  }, [assessments, sittings, filters, sorting]);
};

export const Filters = ({
  sittings,
  children,
  clearBeforeFilters,
}: {
  sittings?: ListSittingsResponse_SittingData[];
  children: ({
    filters,
    filteredSittings,
  }: {
    filters: ReactNode;
    sorts: ReactNode;
    filteredSittings: ListSittingsResponse_SittingData[];
  }) => ReactNode;
  clearBeforeFilters?: boolean;
}) => {
  const { data: subjects = [] } = useSubjects({ suspense: true });
  const { data: assessments } = useAllAssessments();

  // Filters
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = {
    assessment: searchParams.get('assessment') || '',
    yeargroup: searchParams.get('yeargroup') || '',
    subject: searchParams.get('subject') || '',
  };
  const hasFilters = Object.values(filters).some(v => v !== '');

  const patchSearchParams = (changes: Record<string, string>) =>
    setSearchParams(
      p => {
        for (const [k, v] of Object.entries(changes)) {
          p.set(k, v);
        }
        return p;
      },
      { replace: true },
    );

  const _setSubject = (subject: string) => patchSearchParams({ subject });
  const setAssessment = (assessment: string) => patchSearchParams({ assessment });
  const setYeargroup = (yeargroup: string) => patchSearchParams({ yeargroup });

  const clearFilters = () => {
    setSubject('');
    setAssessment('');
    setYeargroup('');
  };

  const groupedAssessments = useMemo(() => {
    const grouped: Record<string, Assessment[]> = {};
    for (const a of assessments?.assessments || []) {
      if (a.status === AssessmentStatus.COMING_SOON) {
        continue; // Skip coming soon assessments
      }
      if (!grouped[a.subjectKey]) {
        grouped[a.subjectKey] = [];
      }
      grouped[a.subjectKey].push(a);
    }
    return grouped;
  }, [assessments]);

  const setSubject = (subject: string) => {
    if (subject && !groupedAssessments[subject]?.find(s => s.name === filters.assessment)) {
      setAssessment('');
    }
    _setSubject(subject);
  };

  const yeargroups = useMemo(
    () =>
      [
        ...new Set(sittings?.map(a => a?.sitting?.ukYeargroup).filter(yg => yg !== undefined)),
      ].sort(),
    [sittings],
  );

  // Clear the yeargroup filter if the selected yeargroup is not in the list of yeargroups
  useEffect(() => {
    if (filters.yeargroup && !yeargroups.includes(parseInt(filters.yeargroup))) {
      setYeargroup(''); // clear
    }
  }, [filters.yeargroup, yeargroups, setYeargroup]);

  const sort = searchParams.get('sort') || 'status';
  const flipped = searchParams.get('flip') === '1';

  const filteredSittings = useFilteredSittings(filters, sittings || [], {
    sort,
    flip: flipped,
  });

  const clearButton = (
    <Button
      leftIcon={<FontAwesomeIcon icon={faTimes} />}
      isDisabled={!hasFilters}
      variant="ghost"
      onClick={clearFilters}
      className={styles.ClearFilterButton}
    >
      Clear filters
    </Button>
  );

  const filterElement = (
    <Stack spacing={2}>
      {clearBeforeFilters && clearButton}
      <Filter value={filters.subject} onValueChange={setSubject}>
        <FilterItem value="">All subjects</FilterItem>
        <Select.Separator className={styles.Separator} />
        {subjects.map(s => (
          <FilterItem key={s.key} value={s.key}>
            {s.name}
          </FilterItem>
        ))}
      </Filter>
      <Filter value={filters.assessment} onValueChange={setAssessment}>
        <FilterItem value="">All assessments</FilterItem>
        <Select.Separator className={styles.Separator} />
        {subjects
          .filter(s => !filters.subject || s.key === filters.subject)
          .map(s => (
            <Select.Group key={s.name} className={styles.DropdownGroup}>
              <Select.Label className={styles.DropdownLabel}>{s.name}</Select.Label>
              {groupedAssessments[s.key].map(a => (
                <FilterItem key={a.name} value={a.name}>
                  {a.displayName}
                </FilterItem>
              ))}
            </Select.Group>
          ))}
      </Filter>
      <Filter value={filters.yeargroup} onValueChange={setYeargroup}>
        <FilterItem value="">All year groups</FilterItem>
        <Select.Separator className={styles.Separator} />
        {yeargroups.map(y => (
          <FilterItem key={y} value={y.toString()}>
            Year {y}
          </FilterItem>
        ))}
      </Filter>
      {!clearBeforeFilters && clearButton}
    </Stack>
  );

  const setSort = (newSort: string) => {
    const patch: Record<string, string> = { sort: newSort, flip: '0' };
    if (newSort === sort) {
      patch.flip = flipped ? '0' : '1';
    }
    patchSearchParams(patch);
  };

  const currentSortIcon = flipped ? faArrowUp : faArrowDown;
  const sortElement = (
    <Stack spacing={4}>
      <span className={styles.SortLabel}>Sort by:</span>
      <div className={styles.SortGroup}>
        <div className={styles.SortFlipButton} onClick={() => setSort(sort)}>
          <FontAwesomeIcon icon={currentSortIcon} />
        </div>
        <Select.Root onValueChange={setSort} value={sort}>
          <Select.Trigger className={classNames(styles.FilterButton, styles.SortButton)}>
            <Select.Value placeholder="SORT" />
            <FontAwesomeIcon icon={faCaretDown} />
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className={styles.Dropdown}>
              <ScrollUpButton />
              <Select.Viewport>
                <FilterItem value="name">Name</FilterItem>
                <FilterItem value="status">Status</FilterItem>
                <FilterItem value="started">Started</FilterItem>
                <FilterItem value="created">Created</FilterItem>
              </Select.Viewport>
              <ScrollDownButton />
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </Stack>
  );

  return children({ filters: filterElement, sorts: sortElement, filteredSittings });
};

// This magic string is used under-the-hood in our Select component to allow
// adding items which reset the select value to the empty string. This is not
// supported by default in Radix; an Item with an empty string value will throw
// an error (e.g. `<Item value="" />`).
// See this issue: https://github.com/radix-ui/primitives/issues/2706
const RADIX_SELECT_EMPTY_STRING = 'RADIX_SELECT_EMPTY_STRING';
const Filter = ({
  children,
  placeholder,
  onValueChange,
  value,
}: PropsWithChildren<{
  placeholder?: string;
  onValueChange: (value: string) => void;
  value?: string;
}>) => (
  <Select.Root
    onValueChange={val => onValueChange(val === RADIX_SELECT_EMPTY_STRING ? '' : val)}
    value={value || RADIX_SELECT_EMPTY_STRING}
  >
    <Select.Trigger
      className={classNames(
        styles.FilterButton,
        value !== '' && styles.FilterButtonActive,
        value === '' && styles.FilterButtonNoValue,
      )}
    >
      <FontAwesomeIcon icon={faFilter} />
      <Select.Value placeholder={placeholder} />
      <FontAwesomeIcon icon={faCaretDown} />
    </Select.Trigger>
    <Select.Portal>
      <Select.Content className={styles.Dropdown}>
        <ScrollUpButton />
        <Select.Viewport>{children}</Select.Viewport>
        <ScrollDownButton />
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);

const ScrollUpButton = () => (
  <Select.ScrollUpButton className={styles.ScrollButton}>
    <FontAwesomeIcon icon={faChevronUp} />
  </Select.ScrollUpButton>
);

const ScrollDownButton = () => (
  <Select.ScrollDownButton className={styles.ScrollButton}>
    <FontAwesomeIcon icon={faChevronDown} />
  </Select.ScrollDownButton>
);

const FilterItem = ({
  value,
  children,
  icon,
}: PropsWithChildren<{ value: string; icon?: IconDefinition }>) => (
  <Select.Item value={value || RADIX_SELECT_EMPTY_STRING} className={styles.DropdownItem}>
    <Select.ItemText>{children}</Select.ItemText>
    <Select.ItemIndicator className={styles.DropdownIndicator}>
      <FontAwesomeIcon icon={icon || faCheck} />
    </Select.ItemIndicator>
  </Select.Item>
);
